import { Grid } from "@mui/material";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import MainTitleDiv from "components/Common/MainTitleDiv";
import TitleDiv from "components/Common/TitleDiv";
import moment from "moment";
import { useState } from "react";
import { FormButton } from "style/theme";
import ERPApi from "system/api/ERPApi";
import { useLoadingDispatch } from "system/context/LoadingContext";

function AdminItem() {
  const Loading = useLoadingDispatch();
  const [prodDate, setProdDate] = useState({
    fromProdDate: moment().format("YYYY-MM-DD"),
    toProdDate: moment().format("YYYY-MM-DD"),
  });
  const [salesDate, setSalesDate] = useState({
    fromSalesDate: moment().format("YYYY-MM-DD"),
    toSalesDate: moment().format("YYYY-MM-DD"),
  });

  const onUpdateProd = () => {
    Loading({ type: "LOADING_MESSAGE", message: "ERP에서 고객 정보를 가져오고 있습니다.(약 5분)" });
    ERPApi.UpdateMasterDataOfProdInfo(prodDate)
      .then(() => alert("업데이트 완료"))
      .catch((err) => console.log(err))
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onUpdateSales = () => {
    Loading({ type: "LOADING_MESSAGE", message: "ERP에서 고객 정보를 가져오고 있습니다.(약 5분)" });
    ERPApi.UpdateMasterDataOfSalesInfo(salesDate)
      .then(() => alert("업데이트 완료"))
      .catch((err) => console.log(err))
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onUpdateCust = () => {
    Loading({ type: "LOADING_MESSAGE", message: "ERP에서 고객 정보를 가져오고 있습니다.(약 5분)" });
    ERPApi.UpdateMasterDataOfCustInfo()
      .then(() => alert("업데이트 완료"))
      .catch((err) => {
        if (err.response && err.response.status === 504) {
          alert("조회 시간이 초과되었습니다. 다시 시도해 주세요.");
        } else {
          console.log(err);
        }
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onUpdateItem = () => {
    Loading({ type: "LOADING_MESSAGE", message: "ERP에서 고객 정보를 가져오고 있습니다.(약 5분)" });
    Promise.all([ERPApi.UpdateMasterDataOfProductCategory(), ERPApi.UpdateMasterDataOfModelCategory()])
      .then((results) => {
        results.forEach(() => alert("업데이트 완료"));
      })
      .catch((err) => console.log(err))
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onUpdateSOInfo = () => {
    Loading({ type: "LOADING_MESSAGE", message: "ERP에서 고객 정보를 가져오고 있습니다.(약 5분)" });
    Promise.all([
      ERPApi.UpdateMasterDataOfIssueType(),
      ERPApi.UpdateMasterDataOfStorage(),
      ERPApi.UpdateMasterDataOfSalesPool(),
      ERPApi.UpdateMasterDataOfDivmode(),
      ERPApi.UpdateMasterDataOfTaxType(),
    ])
      .then((results) => {
        results.forEach(() => alert("업데이트 완료"));
      })
      .catch((err) => console.log(err))
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onUpdatePAC = () => {
    Loading({ type: "LOADING_MESSAGE", message: "ERP에서 고객 정보를 가져오고 있습니다.(약 5분)" });
    Promise.all([
      ERPApi.UpdateMasterDataOfProject(),
      ERPApi.UpdateMasterDataOfActivity(),
      // ERPApi.UpdateMasterDataOfCostCenter(),
    ])
      .then((results) => {
        results.forEach(() => alert("업데이트 완료"));
      })
      .catch((err) => console.log(err))
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const onUpdateNGResult = () => {
    Loading({ type: "LOADING_MESSAGE", message: "ERP에서 고객 정보를 가져오고 있습니다.(약 5분)" });
    Promise.all([
      ERPApi.UpdateMasterDataOfNGCategory(),
      ERPApi.UpdateMasterDataOfNGItemCategory(),
      ERPApi.UpdateMasterDataOfResultCateogry(),
      ERPApi.UpdateMasterDataOfResultItemCateogry(),
    ])
      .then((results) => {
        results.forEach(() => alert("업데이트 완료"));
      })
      .catch((err) => console.log(err))
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="Administrator Menu"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <MainTitleDiv title="항목관리"></MainTitleDiv>
      </Grid>
      <Grid item xs={3}>
        <DateRangePickerComponent
          cssClass="e-outline"
          startDate={moment(prodDate.fromProdDate).toDate()}
          endDate={moment(prodDate.toProdDate).toDate()}
          change={(args: any) => {
            setProdDate({
              fromProdDate: moment(args.startDate).format("YYYY-MM-DD"),
              toProdDate: moment(args.endDate).format("YYYY-MM-DD"),
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormButton onClick={onUpdateProd}>[생산 내역] Update</FormButton>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={3}>
        <DateRangePickerComponent
          cssClass="e-outline"
          startDate={moment(salesDate.fromSalesDate).toDate()}
          endDate={moment(salesDate.toSalesDate).toDate()}
          change={(args: any) => {
            setSalesDate({
              fromSalesDate: moment(args.startDate).format("YYYY-MM-DD"),
              toSalesDate: moment(args.endDate).format("YYYY-MM-DD"),
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormButton onClick={onUpdateSales}>[판매 내역] Update</FormButton>
      </Grid>
      <Grid item xs={6}></Grid>
      <Grid item xs={12}>
        <FormButton onClick={onUpdateCust}>[고객정보] Update</FormButton>
        <FormButton onClick={onUpdateItem}>[제품군, 모델군] Update</FormButton>
        <FormButton onClick={onUpdateNGResult}>[불량구분, 처리결과] 항목 Update</FormButton>
      </Grid>
      <Grid item xs={12}>
        <FormButton onClick={onUpdateSOInfo}>[DivMode, 창고, 모음, 출고유형, Tax] Update</FormButton>
        <FormButton onClick={onUpdatePAC}>[Project, Activity] Update</FormButton>
      </Grid>
    </Grid>
  );
}

export default AdminItem;
