import { Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { useState } from "react";
import "style/form.css";
import { BodyTableCell, FormButton, FormDropDown, FormTextBox, TitleTableCell } from "style/theme";
import { useUserState } from "system/context/UserContext";
import { Payment, ReceiptSendType } from "system/CRMConstants";
import { BaseModel, CSR } from "system/types";
import CustomerSearchModal from "./Modal/CustomerSearchModal";

interface ReportSOProps {
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
  sendSO: () => void;
  activity: BaseModel[];
  project: BaseModel[];
  costCenter: BaseModel[];
}

function ReportSO({ data, setData, sendSO, activity, project, costCenter }: ReportSOProps) {
  const user = useUserState();
  const [openCustSearch, setOpenCustSearch] = useState<boolean>(false);

  const onChangeService = (event: any) => {
    const newData = { ...data };
    newData.serviceInfo = {
      ...newData.serviceInfo,
      [event.target.name]: event.target.value === null ? "" : event.target.value,
    };
    setData(newData);
  };

  const handleOpenCustSearch = () => {
    setOpenCustSearch(true);
  };

  const handleCloseCustSearch = () => {
    setOpenCustSearch(false);
  };

  const onChangeDate = (e: any) => {
    const inputValue = e.target.value ? e.target.value : "";
    const newDate: string = inputValue ? moment(inputValue).format("YYYY-MM-DD") : "";
    onChangeService({ target: { name: [e.target.name], value: newDate } });
  };

  return (
    <>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                {["Admin", "HeadA"].includes(user.authority) && <TitleTableCell align="center"></TitleTableCell>}
                <TitleTableCell align="center">상호명</TitleTableCell>
                <TitleTableCell align="center">사업자번호</TitleTableCell>
                <TitleTableCell align="center">대표자</TitleTableCell>
                <TitleTableCell align="center">이메일</TitleTableCell>
                <TitleTableCell align="center">시/도</TitleTableCell>
                <TitleTableCell align="center">시/군/구</TitleTableCell>
                <TitleTableCell align="center">상세주소</TitleTableCell>
              </TableRow>
              <TableRow>
                {["Admin", "HeadA"].includes(user.authority) && (
                  <BodyTableCell align="center">
                    <FormButton onClick={handleOpenCustSearch}>고객 검색</FormButton>
                  </BodyTableCell>
                )}
                <BodyTableCell align="center">
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="companyName"
                    value={data.serviceInfo.companyName}
                    onChange={onChangeService}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="companyNum"
                    value={data.serviceInfo.companyNum}
                    onChange={onChangeService}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center" width={"150px"}>
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="companyPerson"
                    value={data.serviceInfo.companyPerson}
                    onChange={onChangeService}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="email"
                    onChange={onChangeService}
                    value={data.serviceInfo.email}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center" width={"100px"}>
                  <FormTextBox
                    cssClass="e-outline e-small"
                    fullWidth
                    name="state"
                    value={data.serviceInfo.state}
                    onChange={onChangeService}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center" width={"100px"}>
                  <FormTextBox
                    cssClass="e-outline e-small"
                    fullWidth
                    name="city"
                    value={data.serviceInfo.city}
                    onChange={onChangeService}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center" width={"300px"}>
                  <FormTextBox
                    cssClass="e-outline e-small"
                    fullWidth
                    name="street"
                    value={data.serviceInfo.street}
                    onChange={onChangeService}
                  ></FormTextBox>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <CustomerSearchModal open={openCustSearch} onClose={handleCloseCustSearch} data={data} setData={setData} />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">입금자명</TitleTableCell>
                <TitleTableCell align="center">입금예정일</TitleTableCell>
                {["Admin", "HeadA"].includes(user.authority) && (
                  <>
                    <TitleTableCell align="center">결제여부</TitleTableCell>
                    <TitleTableCell align="center">생산부 발송</TitleTableCell>
                  </>
                )}
                <TitleTableCell align="center">비고</TitleTableCell>
              </TableRow>
              <TableRow>
                <BodyTableCell align="center">
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="depositor"
                    value={data.serviceInfo.depositor}
                    onChange={onChangeService}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    cssClass="e-outline e-small"
                    name="depositDate"
                    value={new Date(data.serviceInfo.depositDate)}
                    onChange={onChangeDate}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      onChangeDate({
                        target: { name: "depositDate", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                {["Admin", "HeadA"].includes(user.authority) && (
                  <>
                    <BodyTableCell align="center">
                      <FormDropDown
                        cssClass="e-outline e-small"
                        name="payment"
                        value={data.serviceInfo.payment || null}
                        dataSource={Payment}
                        onChange={onChangeService}
                      ></FormDropDown>
                    </BodyTableCell>
                    <BodyTableCell align="center">
                      <FormDropDown
                        cssClass="e-outline e-small"
                        name="receiptSend"
                        value={data.serviceInfo.receiptSend || null}
                        dataSource={ReceiptSendType}
                        onChange={onChangeService}
                      ></FormDropDown>
                    </BodyTableCell>
                  </>
                )}
                <BodyTableCell align="center">
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="note"
                    onChange={onChangeService}
                    value={data.serviceInfo.note}
                  ></FormTextBox>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={6}>
        {user.authority !== "BranchUser" && (
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TitleTableCell align="center">Activity</TitleTableCell>
                  <TitleTableCell align="center">Project</TitleTableCell>
                  <TitleTableCell align="center">Cost Center</TitleTableCell>
                </TableRow>
                <TableRow>
                  <BodyTableCell align="center">
                    <FormDropDown
                      cssClass="e-outline e-small"
                      name="activity"
                      value={data.serviceInfo.activity || null}
                      allowFiltering={true}
                      showClearButton={true}
                      onChange={onChangeService}
                      fields={{ text: "name", value: "id" }}
                      dataSource={activity}
                      disabled={!["Admin", "HeadA"].includes(user.authority)}
                    ></FormDropDown>
                  </BodyTableCell>
                  <BodyTableCell align="center">
                    <FormDropDown
                      cssClass="e-outline e-small"
                      name="project"
                      value={data.serviceInfo.project || null}
                      allowFiltering={true}
                      showClearButton={true}
                      onChange={onChangeService}
                      fields={{ text: "name", value: "id" }}
                      dataSource={project}
                      disabled={!["Admin", "HeadA"].includes(user.authority)}
                    ></FormDropDown>
                  </BodyTableCell>
                  <BodyTableCell align="center">
                    <FormDropDown
                      cssClass="e-outline e-small"
                      name="costCenter"
                      value={data.serviceInfo.costCenter || null}
                      allowFiltering={true}
                      showClearButton={true}
                      onChange={onChangeService}
                      fields={{ text: "name", value: "id" }}
                      dataSource={costCenter}
                      disabled={!["Admin", "HeadA"].includes(user.authority)}
                    ></FormDropDown>
                  </BodyTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={4}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                {["Admin", "HeadA"].includes(user.authority) && (
                  <TitleTableCell style={{ width: "80px" }} align="center"></TitleTableCell>
                )}
                <TitleTableCell align="center"> SO처리자</TitleTableCell>
                <TitleTableCell align="center"> 신용카드승인번호</TitleTableCell>
                <TitleTableCell align="center">세금계산서발행일</TitleTableCell>
              </TableRow>
              <TableRow>
                {["Admin", "HeadA"].includes(user.authority) && (
                  <BodyTableCell align="center">
                    <FormButton onClick={sendSO}>SO생성</FormButton>
                  </BodyTableCell>
                )}
                <BodyTableCell align="center">
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="personnelNumber"
                    value={data.serviceInfo.personnelNumber}
                    onChange={onChangeService}
                    disabled={!["Admin", "HeadA"].includes(user.authority)}
                    placeholder={"사번"}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="creditCardNo"
                    value={data.serviceInfo.creditCardNo}
                    onChange={onChangeService}
                    disabled={!["Admin", "HeadA"].includes(user.authority)}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    cssClass="e-outline e-small"
                    name="taxIssueReqDate"
                    value={new Date(data.serviceInfo.taxIssueReqDate)}
                    onChange={onChangeDate}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      onChangeDate({
                        target: { name: "taxIssueReqDate", value: args.value },
                      });
                    }}
                    disabled={!["Admin", "HeadA"].includes(user.authority)}
                  ></DatePickerComponent>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

export default ReportSO;
