import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import moment from "moment";
import { useEffect, useState } from "react";
import "style/form.css";
import {
  BodyTableCell,
  FormDropDown,
  FormNumericBox,
  FormTextBox,
  GreenTitleTableCell,
  LinkButton,
  TitleTableCell,
} from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { MedicalAccident, ServiceBillType } from "system/CRMConstants";
import { Branch, CSR, INIT_PRODINFO, TreeCategory, WaitingPayment } from "system/types";
import RegisterSNModal from "./Modal/RegisterSNModal";

interface ReportProdInfoProps {
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
  flag: boolean;
  setFlag: React.Dispatch<React.SetStateAction<any>>;
  itemCategory: TreeCategory[];
  branch: Branch[];
}

function ReportProdInfo({ data, setData, flag, setFlag, itemCategory, branch }: ReportProdInfoProps) {
  const [paymentList, setPaymentList] = useState<WaitingPayment[]>([]);
  useEffect(() => {
    if (data.prodInfo.serialNum !== "") {
      CRMApi.GetWaitingPayment(data.prodInfo.serialNum)
        .then((res) => {
          setPaymentList(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  }, [data.prodInfo.serialNum, setFlag]);

  const onChangeProdInfo = (event: any) => {
    const newData = { ...data };
    newData.prodInfo = {
      ...newData.prodInfo,
      [event.target.name]: event.target.value,
    };
    setData(newData);
  };
  const handleDateChange = (e: any) => {
    const inputValue = e.target.value ? e.target.value : "";
    const newDate: string = inputValue ? moment(inputValue).format("YYYY-MM-DD") : "";
    onChangeProdInfo({ target: { name: [e.target.name], value: newDate } });
  };

  const [openSN, setOpenSN] = useState<boolean>(false);

  const handleOpenSN = () => {
    setOpenSN(true);
  };

  const handleCloseSN = () => {
    setOpenSN(false);
  };

  const resetProd = () => {
    setData({ ...data, caseId: "", prodInfo: INIT_PRODINFO, soList: data.soList.map((x) => ({ ...x, salesID: "" })) });
    setFlag(false);
  };

  const cancelProd = () => {
    const newData = { ...data };
    newData.prodInfo = {
      ...newData.prodInfo,
      prodDate: "",
      productionPerson: "",
      reqSetupDate: "",
      responsible: "",
      salesDate: "",
      salesOrigin: false,
      salesPartyName: "",
      serialNum: "",
      setupPartyName: "",
      setupResponsible: "",
      warrantyTerm: "",
      warranty: "",
      memo: "",
      di: "",
      accident: false,
    };
    setData(newData);
    setFlag(false);
  };

  const clickSerial = () => {
    if (flag) {
      const formattedText = `${data.prodInfo.serialNum}`;
      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          console.log("Text copied to clipboard:", formattedText);
          alert("클립보드에 복사되었습니다.");
        })
        .catch((error) => {
          console.error("Error copying text to clipboard:", error);
        });
    }
  };

  return (
    <>
      <Grid item xs={5}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}></TableCell>
                <TableCell colSpan={2} align="right" style={{ padding: "0px" }}>
                  <LinkButton onClick={resetProd}>제품신규</LinkButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <GreenTitleTableCell style={{ width: "80px" }} align="center">
                  제품명
                </GreenTitleTableCell>
                <BodyTableCell colSpan={2} align="center">
                  <FormDropDown
                    name="parentCategoryName"
                    cssClass="e-outline e-small"
                    onChange={onChangeProdInfo}
                    value={data.prodInfo.parentCategoryName || null}
                    fields={{ text: "id", value: "id" }}
                    dataSource={itemCategory}
                    readonly={flag}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="categoryName"
                    cssClass="e-outline e-small"
                    onChange={onChangeProdInfo}
                    value={data.prodInfo.categoryName || null}
                    fields={{ text: "name", value: "id" }}
                    dataSource={itemCategory.find((x) => x.id === data.prodInfo.parentCategoryName)?.children || []}
                    readonly={flag}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell colSpan={3} align="center">
                  <FormTextBox
                    name="serialNum"
                    cssClass="e-outline e-small"
                    placeholder="SN"
                    onChange={onChangeProdInfo}
                    value={data.prodInfo.serialNum}
                    readonly={flag}
                    onClick={clickSerial}
                  ></FormTextBox>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <GreenTitleTableCell align="center">생산정보</GreenTitleTableCell>
                <GreenTitleTableCell align="center">생산일</GreenTitleTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    name="prodDate"
                    cssClass="e-outline e-small"
                    width={"130px"}
                    value={new Date(data.prodInfo.prodDate)}
                    onChange={handleDateChange}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      handleDateChange({
                        target: { name: "prodDate", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <LinkButton onClick={handleOpenSN}>SN등록</LinkButton>
                  <LinkButton onClick={cancelProd}>취소</LinkButton>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="productionPerson"
                    cssClass="e-outline e-small"
                    width={"60px"}
                    placeholder="생산자"
                    value={data.prodInfo.productionPerson}
                    onChange={onChangeProdInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell colSpan={2} align="center">
                  {data.prodInfo.salesOrigin}
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <GreenTitleTableCell align="center">DI값</GreenTitleTableCell>
                <BodyTableCell align="center" colSpan={5}>
                  <FormTextBox
                    name="di"
                    cssClass="e-outline e-small"
                    onChange={onChangeProdInfo}
                    value={data.prodInfo.di}
                  ></FormTextBox>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <GreenTitleTableCell align="center">판매정보</GreenTitleTableCell>
                <GreenTitleTableCell align="center">판매일</GreenTitleTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    name="salesDate"
                    cssClass="e-outline e-small"
                    width={"130px"}
                    value={new Date(data.prodInfo.salesDate)}
                    onChange={handleDateChange}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      handleDateChange({
                        target: { name: "salesDate", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="salesPartyName"
                    cssClass="e-outline e-small"
                    placeholder="판매지사"
                    width={"180px"}
                    fields={{ text: "branchName", value: "branchId" }}
                    dataSource={branch}
                    value={data.prodInfo.salesPartyName || null}
                    onChange={onChangeProdInfo}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="responsible"
                    cssClass="e-outline e-small"
                    placeholder="판매자"
                    width={"60px"}
                    value={data.prodInfo.responsible}
                    onChange={onChangeProdInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center" colSpan={2}>
                  <FormNumericBox
                    name="warrantyTerm"
                    format="n0"
                    min={0}
                    step={1}
                    cssClass="e-outline e-small"
                    value={data.prodInfo.warrantyTerm}
                    onChange={onChangeProdInfo}
                  ></FormNumericBox>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <GreenTitleTableCell align="center">납품정보</GreenTitleTableCell>
                <GreenTitleTableCell align="center">납품일</GreenTitleTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    name="reqSetupDate"
                    cssClass="e-outline e-small"
                    width={"130px"}
                    value={new Date(data.prodInfo.reqSetupDate)}
                    onChange={handleDateChange}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      handleDateChange({
                        target: { name: "reqSetupDate", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="setupPartyName"
                    cssClass="e-outline e-small"
                    placeholder="납품지사"
                    width={"180px"}
                    fields={{ text: "branchName", value: "branchId" }}
                    dataSource={branch}
                    value={data.prodInfo.setupPartyName || null}
                    onChange={onChangeProdInfo}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="setupResponsible"
                    cssClass="e-outline e-small"
                    placeholder="납품자"
                    width={"60px"}
                    value={data.prodInfo.setupResponsible}
                    onChange={onChangeProdInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center" colSpan={2}>
                  <FormDropDown
                    name="warranty"
                    cssClass="e-outline e-small"
                    value={data.prodInfo.warranty || null}
                    onChange={onChangeProdInfo}
                    fields={{ text: "key", value: "label" }}
                    dataSource={ServiceBillType}
                  ></FormDropDown>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <GreenTitleTableCell align="center">이전사용처</GreenTitleTableCell>
                <BodyTableCell colSpan={5} align="center">
                  <FormTextBox
                    name="memo"
                    cssClass="e-outline e-small"
                    onChange={onChangeProdInfo}
                    value={data.prodInfo.memo}
                  ></FormTextBox>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <RegisterSNModal
          open={openSN}
          onClose={handleCloseSN}
          serial={data.prodInfo.serialNum}
          data={data}
          setData={setData}
          setFlag={setFlag}
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: "50px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell style={{ width: "80px" }} align="center">
                  CS
                </TitleTableCell>
                <TitleTableCell align="center">날짜</TitleTableCell>
                <TitleTableCell align="center">금액</TitleTableCell>
              </TableRow>
              {paymentList.map((p: WaitingPayment, index: number) => (
                <TableRow key={index}>
                  <TitleTableCell align="center"></TitleTableCell>
                  <BodyTableCell align="center">{p.completedDate}</BodyTableCell>
                  <BodyTableCell align="center">{p.sum}</BodyTableCell>
                </TableRow>
              ))}
              <TableRow>
                <TitleTableCell align="center">의료사고보고</TitleTableCell>
                <BodyTableCell colSpan={2} align="center">
                  <FormDropDown
                    name="accident"
                    cssClass="e-outline e-small"
                    dataSource={MedicalAccident}
                    fields={{ text: "key", value: "label" }}
                    value={data.prodInfo.accident}
                    onChange={onChangeProdInfo}
                  ></FormDropDown>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

export default ReportProdInfo;
