import { Grid, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { useEffect, useState } from "react";
import "style/form.css";
import { BodyTableCell, FormDropDown, FormTextBox, LinkButton, RedTitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import { ErrorHandler } from "system/ApiService";
import { useUserState } from "system/context/UserContext";
import { Branch, Contact, ContactMemo, CSR, INIT_CONTACT, INIT_CUSTINFO, TreeCategory } from "system/types";
import { CRMRoutes } from "system/types/routeList";
import ChangeNameModal from "./Modal/ChangeNameModal";

interface ReportCustInfoProps {
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
  flag: boolean;
  setFlag: React.Dispatch<React.SetStateAction<any>>;
  branch: Branch[];
  address: TreeCategory[];
}

function ReportCustInfo({ data, setData, flag, setFlag, branch, address }: ReportCustInfoProps) {
  const [openName, setOpenName] = useState<boolean>(false);
  const [memo, setMemo] = useState<ContactMemo[]>([]);
  const user = useUserState();

  useEffect(() => {
    if (data.prodInfo.serialNum !== "") {
      CRMApi.GetContactMemo(data.prodInfo.serialNum)
        .then((res) => {
          setMemo(res);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          console.log(msg);
        });
    }
  }, [data.prodInfo.serialNum]);

  const resetCustInfo = () => {
    setData({ ...data, caseId: "", custInfo: INIT_CUSTINFO, soList: data.soList.map((x) => ({ ...x, salesID: "" })) });
    setFlag(false);
  };

  const onChangeCustInfo = (event: any) => {
    const newData = { ...data };
    const { name, value } = event.target;

    if (name === "state") {
      const findState = address.find((item) => item.id === value);
      if (findState?.name) {
        newData.custInfo.branch = findState.name;
      } else newData.custInfo.branch = "";
    } else if (name === "city") {
      const cityItem = address
        .find((item) => item.id === newData.custInfo.state)
        ?.children.find((child) => child.id === value);
      if (cityItem && cityItem?.name !== "") {
        newData.custInfo.branch = cityItem.name;
      }
    }
    newData.custInfo = {
      ...newData.custInfo,
      [name]: value === null ? "" : value,
    };
    setData(newData);
  };

  const handleOpenName = () => {
    setOpenName(true);
  };

  const handleCloseName = () => {
    setOpenName(false);
  };

  const goContactMemo = (id: string) => {
    window.open(
      `${CRMRoutes.view}/${id}`,
      "PrintPage",
      "menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=1200px,height=1050px,left=0,top=0"
    );
  };

  const addContact = () => {
    const newContact: Contact = { ...INIT_CONTACT, custId: data.custInfo.custId };
    const updatedContactList = [...data.custInfo.contactInfo, newContact];
    setData((prevData: CSR) => ({
      ...prevData,
      custInfo: {
        ...prevData.custInfo,
        contactInfo: updatedContactList,
      },
    }));
  };

  const deleteContact = (e: any, index: number) => {
    const contactToDelete = data.custInfo.contactInfo[index];
    // contactId가 0이면 리스트에서 단순 삭제
    if (contactToDelete.contactId === 0) {
      if (data.custInfo.contactInfo.length !== 1) {
        const updatedContactList = [...data.custInfo.contactInfo];
        updatedContactList.splice(index, 1);
        setData((prevData: CSR) => ({
          ...prevData,
          custInfo: {
            ...prevData.custInfo,
            contactInfo: updatedContactList,
          },
        }));
      }
    } else {
      if (window.confirm("CSR과 연동된 모든 이력에서 해당 고객 정보의 연락처가 모두 삭제됩니다.")) {
        CRMApi.DeleteCSRContact(contactToDelete.contactId)
          .then(() => {
            const updatedContactList = [...data.custInfo.contactInfo];
            updatedContactList.splice(index, 1);
            setData((prevData: CSR) => ({
              ...prevData,
              custInfo: {
                ...prevData.custInfo,
                contactInfo: updatedContactList,
              },
            }));
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            console.log(msg);
          });
      }
    }
  };

  const changeContact = (e: any, index: number) => {
    const updatedContact = [...data.custInfo.contactInfo];
    if (e.target.name === "isPrimary") {
      updatedContact[index] = { ...updatedContact[index], [e.target.name]: e.target.checked ? "Yes" : "No" };
    } else {
      updatedContact[index] = { ...updatedContact[index], [e.target.name]: e.target.value };
    }
    const updatedData = { ...data, custInfo: { ...data.custInfo, contactInfo: updatedContact } };
    setData(updatedData);
  };

  const clickCaseId = () => {
    if (data.caseId !== "") {
      const formattedText = `${data.caseId}`;
      navigator.clipboard
        .writeText(formattedText)
        .then(() => {
          console.log("Text copied to clipboard:", formattedText);
          alert("클립보드에 복사되었습니다.");
        })
        .catch((error) => {
          console.error("Error copying text to clipboard:", error);
        });
    }
  };

  return (
    <>
      <Grid item xs={5}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <RedTitleTableCell style={{ width: "80px" }} align="center">
                  문서번호
                </RedTitleTableCell>
                <BodyTableCell align="center" onClick={clickCaseId}>
                  {data.caseId}
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <RedTitleTableCell>상호명</RedTitleTableCell>
                <BodyTableCell colSpan={4} align="center">
                  <FormTextBox
                    name="custName"
                    cssClass="e-outline e-small"
                    value={data.custInfo.custName}
                    onChange={onChangeCustInfo}
                    readonly={flag}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  {["Admin", "HeadA"].includes(user.authority) && (
                    <LinkButton onClick={handleOpenName}>고객이름변경</LinkButton>
                  )}
                  <LinkButton onClick={resetCustInfo} cssClass="e-small">
                    고객신규
                  </LinkButton>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <RedTitleTableCell align="center">주소</RedTitleTableCell>
                <BodyTableCell>
                  <FormDropDown
                    cssClass="e-outline e-small"
                    name="state"
                    placeholder="시/도"
                    showClearButton={true}
                    value={data.custInfo.state || null}
                    dataSource={address.map((x) => x.id)}
                    onChange={onChangeCustInfo}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell>
                  <FormDropDown
                    cssClass="e-outline e-small"
                    name="city"
                    placeholder="시/군/구"
                    showClearButton={true}
                    value={data.custInfo.city || null}
                    dataSource={address.find((x) => x.id === data.custInfo.state)?.children.map((y) => y.id)}
                    onChange={onChangeCustInfo}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell colSpan={3}>
                  <FormTextBox
                    cssClass="e-outline e-small"
                    name="street"
                    placeholder="상세주소"
                    value={data.custInfo.street}
                    onChange={onChangeCustInfo}
                  ></FormTextBox>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <RedTitleTableCell align="center">담당지사</RedTitleTableCell>
                <BodyTableCell colSpan={2}>
                  <FormDropDown
                    cssClass="e-outline e-small"
                    name="branch"
                    placeholder="담당지사"
                    value={data.custInfo.branch || null}
                    fields={{ text: "branchName", value: "branchId" }}
                    dataSource={branch}
                    onChange={onChangeCustInfo}
                  ></FormDropDown>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <RedTitleTableCell align="center">전화번호</RedTitleTableCell>
                <BodyTableCell>구분</BodyTableCell>
                <BodyTableCell>연락 번호/주소</BodyTableCell>
                <BodyTableCell>주</BodyTableCell>
                <BodyTableCell>비고</BodyTableCell>
                <BodyTableCell>
                  <LinkButton onClick={addContact}>추가</LinkButton>
                </BodyTableCell>
              </TableRow>
              {data.custInfo.contactInfo?.map((c: Contact, index: number) => (
                <TableRow key={index}>
                  <RedTitleTableCell align="center"></RedTitleTableCell>
                  <BodyTableCell>
                    <FormDropDown
                      name="type"
                      cssClass="e-outline e-small"
                      value={c.type}
                      dataSource={["Phone", "Email"]}
                      onChange={(e: any) => changeContact(e, index)}
                    ></FormDropDown>
                  </BodyTableCell>
                  <BodyTableCell>
                    <FormTextBox
                      name="locator"
                      value={c.locator}
                      cssClass="e-outline e-small"
                      onChange={(e: any) => changeContact(e, index)}
                    ></FormTextBox>
                  </BodyTableCell>
                  <BodyTableCell>
                    <CheckBoxComponent
                      name="isPrimary"
                      onChange={(e: any) => changeContact(e, index)}
                      checked={c.isPrimary === "Yes"}
                      cssClass="e-small"
                    ></CheckBoxComponent>
                  </BodyTableCell>
                  <BodyTableCell>
                    <FormTextBox
                      name="description"
                      value={c.description}
                      cssClass="e-outline e-small"
                      onChange={(e: any) => changeContact(e, index)}
                    ></FormTextBox>
                  </BodyTableCell>
                  <BodyTableCell>
                    <LinkButton onClick={(e: any) => deleteContact(e, index)}>삭제</LinkButton>
                  </BodyTableCell>
                </TableRow>
              ))}
              <TableRow>
                <RedTitleTableCell colSpan={3} align="center">
                  고객특이사항
                </RedTitleTableCell>
                <RedTitleTableCell colSpan={2} align="center">
                  처리날짜
                </RedTitleTableCell>
                <RedTitleTableCell align="center">상세</RedTitleTableCell>
              </TableRow>
              {memo.map((m) => (
                <TableRow key={m.caseId}>
                  <BodyTableCell colSpan={3} align="center">
                    {m.contactMemo}
                  </BodyTableCell>
                  <BodyTableCell colSpan={2} align="center">
                    {m.completedDate}
                  </BodyTableCell>
                  <BodyTableCell align="center">
                    <LinkButton onClick={() => goContactMemo(m.caseId)}>보기</LinkButton>
                  </BodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ChangeNameModal open={openName} onClose={handleCloseName} data={data} setData={setData} />
      </Grid>
    </>
  );
}

export default ReportCustInfo;
