import { Grid, Table, TableBody, TableContainer, TableRow, Typography } from "@mui/material";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import SignBox from "components/Common/SignBox";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import "style/form.css";
import { PrintBodyTableCell, PrintLinkButton, PrintTitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import {
  CallResult,
  FOCReason,
  HandlingMethod,
  PaymentType,
  ResultForVisit,
  ServiceBillType,
} from "system/CRMConstants";
import { ASRecord, Branch, Contact, CSR, INIT_CSR, SOList } from "system/types";
import { CRMRoutes } from "system/types/routeList";

interface DetailFormProps {
  caseId: string;
}
function DetailForm({ caseId }: DetailFormProps) {
  const [data, setData] = useState<CSR>(INIT_CSR);
  const [record, setRecord] = useState<ASRecord[]>([]);
  const [branch, setBranch] = useState<Branch[]>([]);

  useMemo(() => {
    UserApi.GetBranchList()
      .then((res) => {
        setBranch(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    CRMApi.GetCRMDetail(caseId)
      .then((res) => {
        setData(res);
        CRMApi.GetCSRHistory(res).then((res) => {
          setRecord(res);
        });
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      });
  }, [caseId]);

  const handleViewClick = (caseId: string) => {
    window.open(
      `${CRMRoutes.view}/${caseId}`,
      "PrintPage",
      "menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=1200px,height=1050px,left=0,top=0"
    );
  };

  return (
    <Grid container id="download_area">
      <Grid item xs={10} display="flex" alignItems="center">
        <Typography variant="h3" fontWeight="bold">
          Customer Service Report
        </Typography>
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        <SignBox></SignBox>
      </Grid>
      <Grid item xs={4}>
        <TableContainer style={{ overflowX: "inherit" }}>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center">문서번호</PrintTitleTableCell>
                <PrintBodyTableCell align="center" colSpan={4}>
                  {data.caseId}
                </PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell>상호명</PrintTitleTableCell>
                <PrintBodyTableCell colSpan={4} align="center">
                  {data.custInfo.custName}
                </PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">주소</PrintTitleTableCell>
                <PrintBodyTableCell colSpan={4} align="center">
                  {data.custInfo.state + " " + data.custInfo.city} <br />
                  {data.custInfo.street}
                </PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">담당지사</PrintTitleTableCell>
                <PrintBodyTableCell align="center" colSpan={4}>
                  {branch.find((x) => x.branchId === data.custInfo.branch)?.branchName}
                </PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">전화번호</PrintTitleTableCell>
                <PrintTitleTableCell align="center">구분</PrintTitleTableCell>
                <PrintTitleTableCell align="center">연락 번호/주소</PrintTitleTableCell>
                <PrintTitleTableCell align="center">주</PrintTitleTableCell>
                <PrintTitleTableCell align="center">비고</PrintTitleTableCell>
              </TableRow>
              {data.custInfo.contactInfo.map((c: Contact) => (
                <TableRow key={c.contactId}>
                  <PrintTitleTableCell align="center"></PrintTitleTableCell>
                  <PrintBodyTableCell align="center">{c.type}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{c.locator}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">
                    <CheckBoxComponent cssClass="e-small" disabled checked={c.isPrimary === "Yes"}></CheckBoxComponent>
                  </PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{c.description}</PrintBodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={5} style={{ marginTop: "25px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center">제품명</PrintTitleTableCell>
                <PrintBodyTableCell colSpan={2} align="center">
                  {data.prodInfo.parentCategoryName}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.categoryName}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.serialNum}</PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">생산정보</PrintTitleTableCell>
                <PrintTitleTableCell align="center">생산일</PrintTitleTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.prodDate}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.productionPerson}</PrintBodyTableCell>
                <PrintBodyTableCell align="center"></PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">DI값</PrintTitleTableCell>
                <PrintBodyTableCell colSpan={4}>{data.prodInfo.di}</PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">판매정보</PrintTitleTableCell>
                <PrintTitleTableCell align="center">판매일</PrintTitleTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.salesDate}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {branch.find((x) => x.branchId === data.prodInfo.salesPartyName)?.branchName}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.responsible}</PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">납품정보</PrintTitleTableCell>
                <PrintTitleTableCell align="center">납품일</PrintTitleTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.reqSetupDate}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {branch.find((x) => x.branchId === data.prodInfo.setupPartyName)?.branchName}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.prodInfo.setupResponsible}</PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">이전사용처</PrintTitleTableCell>
                <PrintBodyTableCell colSpan={4}>{data.prodInfo.memo}</PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3} style={{ marginTop: "25px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center"></PrintTitleTableCell>
                <PrintTitleTableCell align="center">날짜</PrintTitleTableCell>
                <PrintTitleTableCell align="center">금액</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">CS</PrintTitleTableCell>
                <PrintBodyTableCell align="center">{"자동완성"}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{"자동완성"}</PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">의료사고보고</PrintTitleTableCell>
                <PrintBodyTableCell align="center" colSpan={2}>
                  {data.prodInfo.accident}
                </PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center">AS이력</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">처리방법</PrintTitleTableCell>
                <PrintTitleTableCell align="center">접수일</PrintTitleTableCell>
                <PrintTitleTableCell align="center">접수자</PrintTitleTableCell>
                <PrintTitleTableCell align="center">불량구분대분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">불량구분소분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리일</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리자</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리결과대분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리결과소분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">방문처리결과</PrintTitleTableCell>
                <PrintTitleTableCell align="center">전화결과</PrintTitleTableCell>
                <PrintTitleTableCell align="center">불량시점</PrintTitleTableCell>
                <PrintTitleTableCell align="center">상세보기</PrintTitleTableCell>
              </TableRow>
              {record.map((r: ASRecord) => (
                <TableRow key={r.caseId}>
                  <PrintBodyTableCell align="center">
                    {HandlingMethod.find((x) => x.label === r.handlingMethod)?.key}
                  </PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.receivedDate}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.receivedPerson}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.ngCategoryId}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.ngCategoryDetailId}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.completedDate}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.completedPerson}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.resultCategoryId}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{r.resultCategoryDetailId}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">
                    {ResultForVisit.find((x) => x.label === r.resultForVisit)?.key}
                  </PrintBodyTableCell>
                  <PrintBodyTableCell align="center">
                    {CallResult.find((x) => x.label === r.callResult)?.key}
                  </PrintBodyTableCell>
                  <PrintBodyTableCell align="center">
                    {ServiceBillType.find((x) => x.label === r.serviceBillType)?.key}
                  </PrintBodyTableCell>
                  <PrintBodyTableCell align="center">
                    <PrintLinkButton onClick={() => handleViewClick(r.caseId)}>보기</PrintLinkButton>
                  </PrintBodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center">CS정보</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">처리방법</PrintTitleTableCell>
                <PrintTitleTableCell align="center">접수일</PrintTitleTableCell>
                <PrintTitleTableCell align="center">접수자</PrintTitleTableCell>
                <PrintTitleTableCell align="center">불량구분대분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">불량구분소분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리일</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리자</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리결과대분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">처리결과소분류</PrintTitleTableCell>
                <PrintTitleTableCell align="center">전화결과</PrintTitleTableCell>
                <PrintTitleTableCell align="center">불량시점</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintBodyTableCell align="center">
                  {HandlingMethod.find((x) => x.label === data.csInfo.handlingMethod)?.key}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.receivedDate}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.receivedPerson}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.ngCategoryId}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.ngCategoryDetailId}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.completedDate}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.completedPerson}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.resultCategoryId}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.resultCategoryDetailId}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {CallResult.find((x) => x.label === data.csInfo.callResult)?.key}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {ServiceBillType.find((x) => x.label === data.csInfo.serviceBillType)?.key}
                </PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell rowSpan={2} align="center">
                  접수 내역
                </PrintTitleTableCell>
                <PrintTitleTableCell align="center">접수시간</PrintTitleTableCell>
                <PrintTitleTableCell align="center">방문예정일</PrintTitleTableCell>
                <PrintTitleTableCell align="center">방문시간</PrintTitleTableCell>
                <PrintTitleTableCell align="center">방문자</PrintTitleTableCell>
                <PrintTitleTableCell align="center">출고일</PrintTitleTableCell>
                <PrintTitleTableCell align="center">수정일시</PrintTitleTableCell>
                <PrintTitleTableCell align="center">고객특이사항</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintBodyTableCell align="center">{data.csInfo.csReceivedTime}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.estimatedDateofVist}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.estimatedTime}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.visitor}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.issueDate}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {moment(data.csInfo.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.contactMemo}</PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintBodyTableCell colSpan={8}>
                  <Typography variant="body2">{data.csInfo.caseNote}</Typography>
                </PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell rowSpan={2} align="center">
                  처리 내역
                </PrintTitleTableCell>
                <PrintTitleTableCell align="center">측정횟수</PrintTitleTableCell>
                <PrintTitleTableCell align="center">Main</PrintTitleTableCell>
                <PrintTitleTableCell align="center">Display</PrintTitleTableCell>
                <PrintTitleTableCell align="center">Print</PrintTitleTableCell>
                <PrintTitleTableCell align="center">Digital</PrintTitleTableCell>
                <PrintTitleTableCell align="center">Analog</PrintTitleTableCell>
                <PrintTitleTableCell align="center">방문처리결과</PrintTitleTableCell>
                <PrintTitleTableCell align="center">비용처리</PrintTitleTableCell>
                <PrintTitleTableCell align="center">무상처리근거</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintBodyTableCell align="center">{data.csInfo.countCheck}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.main}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.display}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.print}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.digital}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.csInfo.analog}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {ResultForVisit.find((x) => x.label === data.csInfo.resultForVisit)?.key}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {PaymentType.find((x) => x.label === data.csInfo.paymentType)?.key}
                </PrintBodyTableCell>
                <PrintBodyTableCell align="center">
                  {FOCReason.find((x) => x.label === data.csInfo.focReason)?.key}
                </PrintBodyTableCell>
              </TableRow>
              <TableRow>
                <PrintBodyTableCell colSpan={10}>
                  <Typography variant="body2">{data.csInfo.serviceNote}</Typography>
                </PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={5} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center">입금자명</PrintTitleTableCell>
                <PrintTitleTableCell align="center">입금예정일</PrintTitleTableCell>
                <PrintTitleTableCell align="center">결제여부</PrintTitleTableCell>
                <PrintTitleTableCell align="center">생산부 발송</PrintTitleTableCell>
                <PrintTitleTableCell align="center">비고</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintBodyTableCell align="center">{data.serviceInfo.depositor}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.serviceInfo.depositDate}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.serviceInfo.payment}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.serviceInfo.receiptSend}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.serviceInfo.note}</PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3} style={{ paddingTop: "0px" }}></Grid>
      <Grid item xs={4} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center">신용카드승인번호</PrintTitleTableCell>
                <PrintTitleTableCell align="center">세금계산서 발행일</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintBodyTableCell align="center">{data.serviceInfo.creditCardNo}</PrintBodyTableCell>
                <PrintBodyTableCell align="center">{data.serviceInfo.taxIssueReqDate}</PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={10} style={{ marginTop: "20px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <PrintTitleTableCell align="center">서비스내역</PrintTitleTableCell>
              </TableRow>
              <TableRow>
                <PrintTitleTableCell align="center">품목번호</PrintTitleTableCell>
                <PrintTitleTableCell align="center">품목명</PrintTitleTableCell>
                <PrintTitleTableCell align="center">수량</PrintTitleTableCell>
                <PrintTitleTableCell align="center">공급가액</PrintTitleTableCell>
                <PrintTitleTableCell align="center">부가세</PrintTitleTableCell>
                <PrintTitleTableCell align="center">금액</PrintTitleTableCell>
                <PrintTitleTableCell align="center">불량 S/N</PrintTitleTableCell>
                <PrintTitleTableCell align="center">교체 S/N</PrintTitleTableCell>
                <PrintTitleTableCell align="center">출고 S/N</PrintTitleTableCell>
                <PrintTitleTableCell align="center">비고</PrintTitleTableCell>
              </TableRow>
              {data.soList.map((s: SOList, index: number) => (
                <TableRow key={s.caseId + index}>
                  <PrintBodyTableCell align="center">{s.itemId}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.itemName}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.qty}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.supplyPrice}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.fee}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.salesPrice}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.ngSerialNum}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.newSerialNum}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.exfacSerialNum}</PrintBodyTableCell>
                  <PrintBodyTableCell align="center">{s.note}</PrintBodyTableCell>
                </TableRow>
              ))}
              <TableRow>
                <PrintTitleTableCell align="center" colSpan={5}>
                  합계(원)
                </PrintTitleTableCell>
                <PrintBodyTableCell align="center">
                  {data.soList.reduce((total, so) => (total = total + so.salesPrice), 0)}
                </PrintBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        <SignBox type="customer"></SignBox>
      </Grid>
    </Grid>
  );
}

export default DetailForm;
