import { Grid, Pagination, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CheckBoxSelection, Inject, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import TitleDiv from "components/Common/TitleDiv";
import fileDownload from "js-file-download";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { BodyTableCell, FormButton, FormDropDown, FormTextBox, TitleTableCell } from "style/theme";
import CRMApi from "system/api/CRMApi";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { useUserState } from "system/context/UserContext";
import {
  HandlingMethod,
  PaymentType,
  ResultForVisit,
  SearchDateType,
  SearchDateType2,
  SearchItemType,
  SearchStatusType,
} from "system/CRMConstants";
import { Branch, CRM, GetInitSearchResult, INIT_CRMSEARCH, SearchCRM, SearchResult, TreeCategory } from "system/types";
import SearchTable from "./SearchTable";

function SearchMain() {
  const Loading = useLoadingDispatch();
  const user = useUserState();
  const [type, setType] = useState("전체보기");
  const [searchForm, setSearchForm] = useState<SearchCRM>(
    user.authority === "BranchUser" ? { ...INIT_CRMSEARCH, branch: user.branch } : INIT_CRMSEARCH
  );
  const [crm, setCRM] = useState<SearchResult<CRM>>(GetInitSearchResult([]));
  const [itemCategory, setItemCategory] = useState<TreeCategory[]>([]);
  const [branch, setBranch] = useState<Branch[]>([]);
  const [ngCategory, setNGCategory] = useState<TreeCategory[]>([]);
  const [resultCategory, setResultCategory] = useState<TreeCategory[]>([]);
  const [ngSub, setNGSub] = useState<TreeCategory[]>([]);
  const [resultSub, setResultSub] = useState<TreeCategory[]>([]);

  useMemo(() => {
    const fetchBaseData = async () => {
      try {
        const [categoryRes, branchRes, ngCategoryRes, resultCategoryRes] = await Promise.all([
          CRMApi.GetItemCategory(false),
          UserApi.GetBranchList(),
          CRMApi.GetNGCategory(),
          CRMApi.GetResultCategory(),
        ]);
        setItemCategory([{ id: "", isShow: true, children: [] }, ...categoryRes]);
        setBranch([{ branchId: "", branchName: "선택" }, ...branchRes]);
        setNGCategory(ngCategoryRes);
        setResultCategory(resultCategoryRes);
      } catch (err: any) {
        let msg = ErrorHandler(err);
        console.log(msg);
      }
    };
    fetchBaseData();
  }, []);

  const onSearch = () => {
    Loading({ type: "LOADING" });

    const searchParams = {
      ...searchForm,
      category: Array.isArray(searchForm.category) ? searchForm.category.join(",") : searchForm.category,
    };

    CRMApi.GetCRMList(searchParams)
      .then((res) => {
        setCRM(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm.page, searchForm.type]);

  const onChangeSearch = (e: any) => {
    if (e.target.name === "parentCategory" && e.target.value !== "") {
      setNGSub(ngCategory.filter((x) => x.name === e.target.value)[0].children);
      setResultSub(resultCategory.filter((x) => x.name === e.target.value)[0].children);
    }
    setSearchForm({ ...searchForm, [e.target.name]: e.target.value });
  };

  const onChangeDate = (e: any) => {
    const inputValue = e.target.value ? e.target.value : "";
    const newDate: string = inputValue ? moment(inputValue).format("YYYY-MM-DD") : "";
    setSearchForm({ ...searchForm, [e.target.name]: newDate });
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setSearchForm({ ...searchForm, page: page });
  };

  const onChangeType = (e: any) => {
    setSearchForm({ ...searchForm, type: e.target.value, page: 1 });
    setType(e.target.value);
  };

  const areSpecificFieldsEmpty = (obj: SearchCRM) => {
    const fieldsToCheck: (keyof SearchCRM)[] = [
      "text",
      "startDate",
      "endDate",
      "ngCategory",
      "resultCategory",
      "parentCategory",
      "handlingMethod",
      "paymentType",
      "resultForVisit",
      "branch",
      "category",
    ];
    return fieldsToCheck.every((field) => obj[field] === "" || obj[field] === "Blank");
  };

  const onExcel = () => {
    const today = moment().format("YYYY-MM-DD");
    const oneYearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");
    let searchFormExcel = { ...searchForm };

    if (areSpecificFieldsEmpty(searchForm)) {
      searchFormExcel = {
        ...searchForm,
        dateType: "completedDate",
        startDate: oneYearAgo,
        endDate: today,
      };
    }

    Loading({ type: "LOADING" });
    CRMApi.DownloadSearchExcel(searchFormExcel)
      .then((res) => {
        fileDownload(res, `CSR내역.xlsx`);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="Search"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">항목</TitleTableCell>
                <BodyTableCell align="center" style={{ display: "flex" }}>
                  <FormDropDown
                    name="classify"
                    value={searchForm.classify}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                    width={"120px"}
                    fields={{ text: "key", value: "label" }}
                    dataSource={SearchItemType}
                  ></FormDropDown>
                  <FormTextBox
                    name="text"
                    value={searchForm.text}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell colSpan={10} style={{ border: "none" }}>
                  <Grid style={{ display: "flex", justifyContent: "space-around" }}>
                    <RadioButtonComponent
                      value="전화미처리"
                      label="전화미처리"
                      checked={type === "전화미처리"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                    <RadioButtonComponent
                      value="방문일정"
                      label="방문일정"
                      checked={type === "방문일정"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                    {["Admin", "HeadA"].includes(user.authority) && (
                      <RadioButtonComponent
                        value="해피콜"
                        label="해피콜"
                        checked={type === "해피콜"}
                        onChange={onChangeType}
                      ></RadioButtonComponent>
                    )}
                    <RadioButtonComponent
                      value="채권"
                      label="채권"
                      checked={type === "채권"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                    {/* 본사영업_에 해당하는 branch만 보이도록 */}
                    {["Admin", "HeadA"].includes(user.authority) &&
                      ["HC005342", "HC005343", "HC005345", "HC007964"].includes(user.branch) && (
                        <RadioButtonComponent
                          value="SO생성"
                          label="SO생성"
                          checked={type === "SO생성"}
                          onChange={onChangeType}
                        ></RadioButtonComponent>
                      )}
                    <RadioButtonComponent
                      value="전체보기"
                      label="전체보기"
                      checked={type === "전체보기"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                  </Grid>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">날짜</TitleTableCell>
                <BodyTableCell align="center" style={{ display: "flex", alignItems: "center" }}>
                  <DatePickerComponent
                    name="startDate"
                    value={new Date(searchForm.startDate)}
                    onChange={onChangeDate}
                    cssClass="e-outline e-small"
                    width={"120px"}
                    placeholder="시작일"
                  ></DatePickerComponent>
                  <span> ~ </span>
                  <DatePickerComponent
                    name="endDate"
                    value={new Date(searchForm.endDate)}
                    onChange={onChangeDate}
                    cssClass="e-outline e-small"
                    width={"120px"}
                    placeholder="종료일"
                  ></DatePickerComponent>
                  <FormDropDown
                    name="dateType"
                    value={searchForm.dateType}
                    onChange={onChangeSearch}
                    fields={{ text: "key", value: "label" }}
                    dataSource={searchForm.handlingMethod === "Visit" ? SearchDateType2 : SearchDateType}
                    cssClass="e-outline e-small"
                    width={"120px"}
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">처리방법</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="handlingMethod"
                    value={searchForm.handlingMethod}
                    fields={{ text: "key", value: "label" }}
                    dataSource={HandlingMethod}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">방문처리결과</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultForVisit"
                    value={searchForm.resultForVisit}
                    fields={{ text: "key", value: "label" }}
                    dataSource={ResultForVisit}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">비용처리</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="paymentType"
                    value={searchForm.paymentType}
                    fields={{ text: "key", value: "label" }}
                    dataSource={PaymentType}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">작성지사</TitleTableCell>
                <BodyTableCell align="center">
                  {user.authority === "BranchUser" ? (
                    <FormDropDown
                      name="branch"
                      value={user.branch}
                      dataSource={branch.filter((x) => x.branchId === user.branch)}
                      fields={{ text: "branchName", value: "branchId" }}
                      cssClass="e-outline e-small"
                      popupWidth="200px"
                    ></FormDropDown>
                  ) : (
                    <FormDropDown
                      name="branch"
                      value={searchForm.branch}
                      dataSource={branch}
                      fields={{ text: "branchName", value: "branchId" }}
                      onChange={onChangeSearch}
                      cssClass="e-outline e-small"
                      popupWidth="200px"
                    ></FormDropDown>
                  )}
                </BodyTableCell>
                {["Admin", "HeadA"].includes(user.authority) && (
                  <>
                    <TitleTableCell align="center"> IHQ 발행</TitleTableCell>
                    <BodyTableCell align="center">
                      <FormDropDown
                        name="erp"
                        value={searchForm.erp}
                        onChange={onChangeSearch}
                        fields={{ text: "key", value: "label" }}
                        dataSource={SearchStatusType}
                        cssClass="e-outline e-small"
                      ></FormDropDown>
                    </BodyTableCell>
                  </>
                )}
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">제품선택</TitleTableCell>
                <BodyTableCell align="center" style={{ display: "flex", alignItems: "center" }}>
                  <FormDropDown
                    name="parentCategory"
                    value={searchForm.parentCategory}
                    fields={{ text: "id", value: "id" }}
                    dataSource={itemCategory || []}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                    width="120px"
                  ></FormDropDown>
                  <MultiSelectComponent
                    name="category"
                    fields={{ text: "name", value: "id" }}
                    dataSource={
                      itemCategory
                        .find((x) => x.id === searchForm.parentCategory)
                        ?.children.map((child) => ({ id: child.id, name: child.name })) || []
                    }
                    onChange={onChangeSearch}
                    mode="CheckBox"
                    placeholder="선택"
                    allowFiltering={false}
                    showSelectAll={true}
                    selectAllText="전체선택"
                    unSelectAllText="전체해제"
                    cssClass="e-outline e-small"
                    width={"250px"}
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </BodyTableCell>
                <TitleTableCell align="center">불량구분대분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="ngCategory"
                    value={searchForm.ngCategory}
                    fields={{ text: "name", value: "name" }}
                    dataSource={ngSub}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">불량구분소분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="ngCategoryDetail"
                    value={searchForm.ngCategoryDetail}
                    fields={{ text: "name", value: "name" }}
                    dataSource={ngSub.find((x) => x.name === searchForm.ngCategory)?.children}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">처리결과대분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultCategory"
                    value={searchForm.resultCategory}
                    fields={{ text: "name", value: "name" }}
                    dataSource={resultSub}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">처리결과소분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultCategoryDetail"
                    value={searchForm.resultCategoryDetail}
                    fields={{ text: "name", value: "name" }}
                    dataSource={resultSub.find((x) => x.name === searchForm.resultCategory)?.children}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  총 {crm.count}건
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: "0px" }}>
                  <FormButton onClick={onExcel}>엑셀</FormButton>
                  <FormButton onClick={onSearch}>조회</FormButton>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <SearchTable type={type} data={crm.list}></SearchTable>
        <Pagination
          count={crm.totalPage}
          page={searchForm.page}
          onChange={onPageChange}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
}

export default SearchMain;
