import { Grid } from "@mui/material";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject } from "@syncfusion/ej2-react-grids";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LinkButton } from "style/theme";
import { CallResult, HandlingMethod, PaymentType, ResultForVisit } from "system/CRMConstants";
import { CRM } from "system/types";
import { CRMRoutes } from "system/types/routeList";

interface SearchTableProps {
  type: string;
  data: CRM[];
}

function SearchTable({ type, data }: SearchTableProps) {
  const history = useHistory();

  const handleSearch = (caseId: string) => {
    history.push(`${CRMRoutes.report}/${caseId}`);
  };

  const handleViewClick = (caseId: string): void => {
    window.open(
      `${CRMRoutes.view}/${caseId}`,
      "PrintPage",
      "menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=yes,resizable=yes,width=1200px,height=1050px,left=0,top=0"
    );
  };

  const CustomTemplate = useCallback((props: any): any => {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <LinkButton onClick={() => handleViewClick(props.caseId)}>보기</LinkButton>
        <LinkButton onClick={() => handleSearch(props.caseId)}>수정</LinkButton>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlingTemplate = useCallback((props: any): any => {
    return <>{HandlingMethod.find((item) => item.label === props.handlingMethod)?.key}</>;
  }, []);
  const callresultTemplate = useCallback((props: any): any => {
    return <>{CallResult.find((item) => item.label === props.callResult)?.key}</>;
  }, []);
  const resultvisitTemplate = useCallback((props: any): any => {
    return <>{ResultForVisit.find((item) => item.label === props.resultForVisit)?.key}</>;
  }, []);
  const paymentTemplate = useCallback((props: any): any => {
    return <>{PaymentType.find((item) => item.label === props.paymentType)?.key}</>;
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GridComponent className="crmSearch" dataSource={data} gridLines="Both">
          <ColumnsDirective>
            <ColumnDirective
              field="handlingMethod"
              headerText="처리방법"
              textAlign="Center"
              headerTextAlign="Center"
              template={handlingTemplate}
              width="45"
            />
            <ColumnDirective
              field="caseId"
              headerText="문서번호"
              textAlign="Center"
              headerTextAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="receivedDate"
              headerText="접수일"
              textAlign="Center"
              headerTextAlign="Center"
              width="80"
            />
            <ColumnDirective
              field="custName"
              headerText="상호"
              textAlign="Center"
              headerTextAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="categoryName"
              headerText="제품명"
              textAlign="Center"
              headerTextAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="branch"
              headerText="담당지사"
              textAlign="Center"
              headerTextAlign="Center"
              width="100"
            />
            <ColumnDirective
              field="address"
              headerText="주소"
              textAlign="Center"
              headerTextAlign="Center"
              width="200"
            />
            <ColumnDirective
              field="receivedPerson"
              headerText="접수자"
              textAlign="Center"
              headerTextAlign="Center"
              width="50"
            />
            {type !== "SO생성" && (
              <ColumnDirective
                field="ngCategoryId"
                headerText="불량구분대분류"
                textAlign="Center"
                headerTextAlign="Center"
                width="100"
              />
            )}
            {type !== "SO생성" && (
              <ColumnDirective
                field="ngCategoryDetailId"
                headerText="분량구분소분류"
                textAlign="Center"
                headerTextAlign="Center"
                width="100"
              />
            )}
            {type !== "SO생성" && (
              <ColumnDirective
                field="resultCategoryId"
                headerText="처리결과대분류"
                textAlign="Center"
                headerTextAlign="Center"
                width="100"
              />
            )}
            {type !== "SO생성" && (
              <ColumnDirective
                field="resultCategoryDetailId"
                headerText="처리결과소분류"
                textAlign="Center"
                headerTextAlign="Center"
                width="100"
              />
            )}
            {type === "방문일정" && (
              <ColumnDirective
                field="estimatedDateofVist"
                headerText="방문예정일"
                textAlign="Center"
                headerTextAlign="Center"
                width="80"
              />
            )}
            {!["전화미처리", "방문일정"].includes(type) && (
              <ColumnDirective
                field="completedDate"
                headerText="처리일"
                textAlign="Center"
                headerTextAlign="Center"
                width="80"
              />
            )}
            <ColumnDirective
              field="completedPerson"
              headerText="처리자"
              textAlign="Center"
              headerTextAlign="Center"
              width="50"
            />
            {["전화미처리", "방문일정", "해피콜", "전체보기"].includes(type) && (
              <ColumnDirective
                field="callResult"
                headerText="전화결과"
                textAlign="Center"
                headerTextAlign="Center"
                template={callresultTemplate}
                width="50"
              />
            )}
            {["방문일정", "해피콜", "채권", "SO생성", "전체보기"].includes(type) && (
              <ColumnDirective
                field="resultForVisit"
                headerText="방문처리결과"
                textAlign="Center"
                headerTextAlign="Center"
                template={resultvisitTemplate}
                width="50"
              />
            )}
            {["채권", "SO생성"].includes(type) && (
              <ColumnDirective field="sum" headerText="합계" textAlign="Center" headerTextAlign="Center" width="50" />
            )}
            {["채권", "SO생성"].includes(type) && (
              <ColumnDirective
                field="paymentType"
                headerText="비용처리"
                textAlign="Center"
                headerTextAlign="Center"
                template={paymentTemplate}
                width="100"
              />
            )}
            {type === "SO생성" && (
              <ColumnDirective
                field="taxIssueReqDate"
                headerText="계산서 발행일자"
                textAlign="Center"
                headerTextAlign="Center"
                width="80"
              />
            )}
            {type === "SO생성" && (
              <ColumnDirective
                field="salesID"
                headerText="SO"
                textAlign="Center"
                headerTextAlign="Center"
                width="100"
              />
            )}
            {type === "SO생성" && (
              <ColumnDirective
                field="salesStatus"
                headerText="상태"
                textAlign="Center"
                headerTextAlign="Center"
                width="80"
              />
            )}
            <ColumnDirective
              field="caseId"
              headerText="상세보기"
              textAlign="Center"
              headerTextAlign="Center"
              template={CustomTemplate}
              width="40"
            />
          </ColumnsDirective>
          <Inject services={[]} />
        </GridComponent>
      </Grid>
    </Grid>
  );
}

export default SearchTable;
