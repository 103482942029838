import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import moment from "moment";
import { useRef } from "react";
import "style/form.css";
import { BodyTableCell, FormDropDown, FormTextBox, LinkButton, TitleTableCell } from "style/theme";
import { useUserState } from "system/context/UserContext";
import {
  CallResult,
  FOCReason,
  HandlingMethod,
  PaymentType,
  ResultForVisit,
  ServiceBillType,
} from "system/CRMConstants";
import { isNextDay } from "system/Helper";
import { CSR, INIT_CSINFO, INIT_SERVICEINFO, INIT_SOLIST, TreeCategory } from "system/types";

interface ReportCSInfoProps {
  data: CSR;
  setData: React.Dispatch<React.SetStateAction<any>>;
  ngCategory: TreeCategory[];
  resultCategory: TreeCategory[];
}

function ReportCSInfo({ data, setData, ngCategory, resultCategory }: ReportCSInfoProps) {
  const user = useUserState();
  const caseNoteRef = useRef<any>(null);
  const serviceNoteRef = useRef<any>(null);

  const onChangeCSInfo = (event: any) => {
    const newData = { ...data };
    const name = event.target.name;
    const value = event.target.value === null ? "" : event.target.value;

    // 기본 필드 업데이트
    newData.csInfo = {
      ...newData.csInfo,
      [name]: value,
    };

    if (
      name === "resultCategoryId" &&
      newData.csInfo.callResult === "CS" &&
      ["Visit", "Collection", "Parcel"].includes(newData.csInfo.handlingMethod)
    ) {
      newData.csInfo.completedDate = moment().format("YYYY-MM-DD");
      if (!newData.csInfo.completedPerson) {
        newData.csInfo.completedPerson = user.name;
      }
    } else if (name === "handlingMethod") {
      newData.csInfo.estimatedDateofVist = value === "Visit" ? isNextDay() : "";
    } else if (name === "callResult" && value === "CS") {
      newData.csInfo.handlingMethod = "Visit";
      newData.csInfo.estimatedDateofVist = isNextDay();
    }

    const { callResult, handlingMethod } = newData.csInfo;
    if (handlingMethod === "Call") {
      if (["Blank", "NotCall", "CS"].includes(callResult)) {
        newData.csInfo.completedDate = "";
        newData.csInfo.completedPerson = "";
        newData.csInfo.estimatedDateofVist = "";
        newData.csInfo.issueDate = "";
      } else if (callResult === "Material") {
        newData.csInfo.completedDate = moment().format("YYYY-MM-DD");
        newData.csInfo.issueDate = moment().format("YYYY-MM-DD");
        newData.csInfo.estimatedDateofVist = "";
        if (!newData.csInfo.completedPerson) {
          newData.csInfo.completedPerson = user.name;
        }
      } else {
        newData.csInfo.completedDate = moment().format("YYYY-MM-DD");
        newData.csInfo.estimatedDateofVist = "";
        newData.csInfo.issueDate = "";
        if (!newData.csInfo.completedPerson) {
          newData.csInfo.completedPerson = user.name;
        }
      }
    }

    setData(newData);
  };

  const handleDateChange = (e: any) => {
    const inputValue = e.target.value ? e.target.value : "";
    const newDate: string = inputValue ? moment(inputValue).format("YYYY-MM-DD") : "";
    onChangeCSInfo({ target: { name: [e.target.name], value: newDate } });
  };

  const handleTimeChange = (e: any) => {
    onChangeCSInfo({ target: { name: [e.target.name], value: e.target.value } });
  };

  const resetCS = () => {
    const newData = { ...data, caseId: "" };
    newData.serviceInfo = { ...INIT_SERVICEINFO };
    newData.csInfo = { ...INIT_CSINFO, receivedPerson: user.name, receivedBranch: user.branch };
    newData.soList = [{ ...INIT_SOLIST }];
    setData(newData);
  };

  const onCreate = (textareaObj: any): void => {
    textareaObj.addAttributes({ rows: 10 });
    textareaObj.respectiveElement.style.height = "auto";
    textareaObj.respectiveElement.style.height = textareaObj.respectiveElement.scrollHeight + "px";
  };

  const onInput = (textareaObj: any): void => {
    textareaObj.respectiveElement.style.height = "auto";
    textareaObj.respectiveElement.style.height = textareaObj.respectiveElement.scrollHeight + "px";
  };

  return (
    <>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell style={{ width: "80px" }} align="center">
                  CS정보
                </TitleTableCell>
                <TableCell colSpan={9}></TableCell>
                <TableCell align="right">
                  <LinkButton onClick={resetCS}>CS신규</LinkButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">처리방법</TitleTableCell>
                <TitleTableCell align="center">접수일</TitleTableCell>
                <TitleTableCell align="center">접수자</TitleTableCell>
                <TitleTableCell align="center">불량구분대분류</TitleTableCell>
                <TitleTableCell align="center">불량구분소분류</TitleTableCell>
                <TitleTableCell align="center">처리일</TitleTableCell>
                <TitleTableCell align="center">처리자</TitleTableCell>
                <TitleTableCell align="center">처리결과대분류</TitleTableCell>
                <TitleTableCell align="center">처리결과소분류</TitleTableCell>
                <TitleTableCell align="center">전화결과</TitleTableCell>
                <TitleTableCell align="center">불량시점</TitleTableCell>
              </TableRow>
              <TableRow>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="handlingMethod"
                    cssClass="e-outline e-small"
                    value={data.csInfo.handlingMethod || null}
                    onChange={onChangeCSInfo}
                    fields={{ text: "key", value: "label" }}
                    dataSource={HandlingMethod}
                    width={"80px"}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    name="receivedDate"
                    cssClass="e-outline e-small"
                    value={new Date(data.csInfo.receivedDate)}
                    onChange={handleDateChange}
                    width={"130px"}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      handleDateChange({
                        target: { name: "receivedDate", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="receivedPerson"
                    cssClass="e-outline e-small"
                    value={data.csInfo.receivedPerson}
                    onChange={onChangeCSInfo}
                    width={"80px"}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="ngCategoryId"
                    cssClass="e-outline e-small"
                    value={data.csInfo.ngCategoryId || null}
                    fields={{ text: "name", value: "name" }}
                    dataSource={ngCategory.find((x) => x.name === data.prodInfo.parentCategoryName)?.children || []}
                    onChange={onChangeCSInfo}
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="ngCategoryDetailId"
                    cssClass="e-outline e-small"
                    value={data.csInfo.ngCategoryDetailId || null}
                    fields={{ text: "name", value: "name" }}
                    dataSource={
                      ngCategory
                        .find((x) => x.name === data.prodInfo.parentCategoryName)
                        ?.children.find((x) => x.name === data.csInfo.ngCategoryId)?.children || []
                    }
                    onChange={onChangeCSInfo}
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    name="completedDate"
                    cssClass="e-outline e-small"
                    value={new Date(data.csInfo.completedDate)}
                    onChange={handleDateChange}
                    width={"130px"}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      handleDateChange({
                        target: { name: "completedDate", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="completedPerson"
                    cssClass="e-outline e-small"
                    value={data.csInfo.completedPerson}
                    onChange={onChangeCSInfo}
                    width={"80px"}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultCategoryId"
                    cssClass="e-outline e-small"
                    showClearButton={true}
                    value={data.csInfo.resultCategoryId || null}
                    fields={{ text: "name", value: "name" }}
                    dataSource={resultCategory.find((x) => x.name === data.prodInfo.parentCategoryName)?.children || []}
                    onChange={onChangeCSInfo}
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultCategoryDetailId"
                    cssClass="e-outline e-small"
                    showClearButton={true}
                    value={data.csInfo.resultCategoryDetailId || null}
                    fields={{ text: "name", value: "name" }}
                    dataSource={
                      resultCategory
                        .find((x) => x.name === data.prodInfo.parentCategoryName)
                        ?.children.find((x) => x.name === data.csInfo.resultCategoryId)?.children || []
                    }
                    onChange={onChangeCSInfo}
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="callResult"
                    cssClass="e-outline e-small"
                    value={data.csInfo.callResult || null}
                    onChange={onChangeCSInfo}
                    fields={{ text: "key", value: "label" }}
                    dataSource={CallResult}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="serviceBillType"
                    cssClass="e-outline e-small"
                    value={data.csInfo.serviceBillType || null}
                    onChange={onChangeCSInfo}
                    fields={{ text: "key", value: "label" }}
                    dataSource={ServiceBillType}
                  ></FormDropDown>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell style={{ width: "80px" }} rowSpan={2} align="center">
                  접수 내역
                </TitleTableCell>
                <TitleTableCell align="center">접수시간</TitleTableCell>
                <TitleTableCell align="center">방문예정일</TitleTableCell>
                <TitleTableCell align="center">방문시간</TitleTableCell>
                <TitleTableCell align="center">방문자</TitleTableCell>
                <TitleTableCell align="center">출고일</TitleTableCell>
                <TitleTableCell align="center">수정일시</TitleTableCell>
                <TitleTableCell align="center">고객특이사항</TitleTableCell>
              </TableRow>
              <TableRow>
                <BodyTableCell align="center">
                  <input
                    type="time"
                    name="csReceivedTime"
                    value={data.csInfo.csReceivedTime}
                    onChange={handleTimeChange}
                    style={{
                      font: "inherit",
                      width: "100%",
                      height: "35px",
                      borderRadius: "6px",
                      border: "solid 1px #d9d9d9",
                    }}
                  ></input>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    name="estimatedDateofVist"
                    cssClass="e-outline e-small"
                    value={new Date(data.csInfo.estimatedDateofVist)}
                    onChange={handleDateChange}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      handleDateChange({
                        target: { name: "estimatedDateofVist", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <input
                    type="time"
                    name="estimatedTime"
                    value={data.csInfo.estimatedTime}
                    onChange={handleTimeChange}
                    min="08:00"
                    max="22:00"
                    style={{
                      font: "inherit",
                      width: "100%",
                      height: "35px",
                      borderRadius: "6px",
                      border: "solid 1px #d9d9d9",
                    }}
                  ></input>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="visitor"
                    cssClass="e-outline e-small"
                    value={data.csInfo.visitor}
                    onChange={onChangeCSInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <DatePickerComponent
                    name="issueDate"
                    cssClass="e-outline e-small"
                    value={new Date(data.csInfo.issueDate)}
                    onChange={handleDateChange}
                    format="yyyy-MM-dd"
                    change={(args: any) => {
                      handleDateChange({
                        target: { name: "issueDate", value: args.value },
                      });
                    }}
                  ></DatePickerComponent>
                </BodyTableCell>
                <BodyTableCell align="center">
                  {data.csInfo.updatedAt === "" ? "" : moment(data.csInfo.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                </BodyTableCell>
                <BodyTableCell align="center" style={{ whiteSpace: "nowrap", minWidth: "300px" }}>
                  <FormTextBox
                    name="contactMemo"
                    cssClass="e-outline e-small"
                    value={data.csInfo.contactMemo}
                    onChange={onChangeCSInfo}
                    fullWidth
                  ></FormTextBox>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <FormTextBox
          name="caseNote"
          cssClass="e-outline"
          multiline={true}
          input={() => onInput(caseNoteRef.current)}
          created={() => onCreate(caseNoteRef.current)}
          value={data.csInfo.caseNote}
          onChange={onChangeCSInfo}
          ref={(scope: any) => {
            caseNoteRef.current = scope;
          }}
        ></FormTextBox>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell style={{ width: "80px" }} rowSpan={2} align="center">
                  처리 내역
                </TitleTableCell>
                <TitleTableCell align="center">측정횟수</TitleTableCell>
                <TitleTableCell align="center">Main</TitleTableCell>
                <TitleTableCell align="center">Display</TitleTableCell>
                <TitleTableCell align="center">Print</TitleTableCell>
                <TitleTableCell align="center">Digital</TitleTableCell>
                <TitleTableCell align="center">Analog</TitleTableCell>
                <TitleTableCell align="center">방문처리결과</TitleTableCell>
                <TitleTableCell align="center">비용처리</TitleTableCell>
                <TitleTableCell align="center">무상처리근거</TitleTableCell>
              </TableRow>
              <TableRow>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="countCheck"
                    cssClass="e-outline e-small"
                    value={data.csInfo.countCheck}
                    onChange={onChangeCSInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="main"
                    cssClass="e-outline e-small"
                    value={data.csInfo.main}
                    onChange={onChangeCSInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="display"
                    cssClass="e-outline e-small"
                    value={data.csInfo.display}
                    onChange={onChangeCSInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="print"
                    cssClass="e-outline e-small"
                    value={data.csInfo.print}
                    onChange={onChangeCSInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="digital"
                    cssClass="e-outline e-small"
                    value={data.csInfo.digital}
                    onChange={onChangeCSInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormTextBox
                    name="analog"
                    cssClass="e-outline e-small"
                    value={data.csInfo.analog}
                    onChange={onChangeCSInfo}
                  ></FormTextBox>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultForVisit"
                    cssClass="e-outline e-small"
                    value={data.csInfo.resultForVisit || null}
                    onChange={onChangeCSInfo}
                    fields={{ text: "key", value: "label" }}
                    dataSource={ResultForVisit}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="paymentType"
                    cssClass="e-outline e-small"
                    value={data.csInfo.paymentType || null}
                    onChange={onChangeCSInfo}
                    fields={{ text: "key", value: "label" }}
                    dataSource={PaymentType}
                  ></FormDropDown>
                </BodyTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="focReason"
                    cssClass="e-outline e-small"
                    value={data.csInfo.focReason || null}
                    onChange={onChangeCSInfo}
                    fields={{ text: "key", value: "label" }}
                    dataSource={FOCReason}
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TextBoxComponent
          name="serviceNote"
          cssClass="e-outline"
          multiline={true}
          input={() => onInput(serviceNoteRef.current)}
          created={() => onCreate(serviceNoteRef.current)}
          value={data.csInfo.serviceNote}
          onChange={onChangeCSInfo}
          ref={(scope) => {
            serviceNoteRef.current = scope;
          }}
        />
      </Grid>
    </>
  );
}

export default ReportCSInfo;
